import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from "vue-i18n";
import {defaultLocale, languages} from "./i18n/index.js";

import api, {default_api_error, default_api_response} from "anam-library/src/services/api";
import PluginKeycloak from "./plugins/keycloak-anamnese";
import VueMatomo from 'vue-matomo'

Vue.use(VueI18n);
Vue.use(PluginKeycloak)
Vue.use(VueMatomo, {
    host: 'https://matomo.anamnese.care/',
    siteId: 2,
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    debug: true
});

const messages = Object.assign(languages);

Vue.config.productionTip = false

export const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: "fr",
    messages,
    silentTranslationWarn: true
});

Vue.prototype.$http = api;
api.interceptors.response.use(response => {
        return default_api_response(response);
    }, error => {
        return default_api_error(error, api, store.dispatch);
    }
);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
